<template>
  <transition name="modal">
    <div class="uploader-modal__overlay" v-if="isShow">
      <div class="modal-body">
        <div
          class="modal-body__overlay dragscroll"
          :style="{ overflow: isDesktop ? 'hidden' : 'auto' }"
          ref="imageWrapper"
        >
          <div
            class="modal-body__img"
            :style="{ backgroundImage: `url(${this.url})`, transform: `scale(${imageZoom})` }"
            :class="{ grabbing: imageZoom > 1 }"
          >
            <!--<img :src="this.url"
                 :style="{transform: `scale(${imageZoom})`}"
                 ref="loadedImage"
                 alt="">-->
          </div>
        </div>
        <div class="modal-body__nav">
          <button class="btn" @click="close">
            <img src="@/assets/img/icons/uploader--modal-close.svg" alt="❌" />
          </button>
          <div class="modal-body__nav-zoom">
            <button class="btn" @click="updateDrag" :disabled="imageZoom >= 4">
              <img src="@/assets/img/icons/uploader--modal-zoomIn.svg" alt="+" />
            </button>
            <button class="btn" @click="imageZoom -= 1" :disabled="imageZoom <= 1">
              <img src="@/assets/img/icons/uploader--modal-zoomOut.svg" alt="-" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import dragscroll from 'dragscroll/dragscroll.js'
import device from 'current-device'
export default {
  directives: {
    dragscroll: dragscroll
  },
  props: {
    isShow: Boolean,
    url: String
  },
  data: () => ({
    imageZoom: 1,
    isDesktop: ''
  }),
  mounted() {
    device.desktop() ? (this.isDesktop = true) : (this.isDesktop = false)
  },
  methods: {
    updateDrag() {
      dragscroll.reset()
      this.imageZoom += 1
    },
    close() {
      this.$emit('close')
    }
  },
  watch: {
    imageZoom() {
      if (this.imageZoom > 1) {
        // const realHeight = this.$refs.loadedImage.naturalHeight;
        // const realWidth = this.$refs.loadedImage.naturalWidth;
        const wrapper = this.$refs.imageWrapper
        const realHeight = wrapper.offsetHeight
        const realWidth = wrapper.offsetWidth
        setTimeout(() => {
          wrapper.scrollTo(
            (realWidth * this.imageZoom) / 2 - realWidth / 2,
            (realHeight * this.imageZoom) / 2 - realHeight / 2
          )
        }, 0)
      }
    },
    isShow() {
      if (this.isShow) {
        this.imageZoom = 1
        dragscroll.reset()
      }
    }
  }
}
</script>

<style scoped lang="sass">
.uploader-modal
  &__overlay
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 5
    background: rgba(51, 51, 51, 0.41)
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    .modal-body
      max-width: rem(960)
      box-sizing: border-box
      overflow: hidden
      display: flex
      +media((flex-direction: (320: column-reverse, 663: row)))
      align-items: center
      width: 100%
      +media((height: (320: auto, 663: 100%)))
      +media((max-height: (320: 80%, 663: rem(663))))
      +media((max-width: (320: 90%, 960: rem(960))))
      background: #FFFFFF
      box-shadow: $default-box-shadow
      border-radius: rem(4)
      +media((padding: (320: 0 rem(12) rem(12), 663: rem(12) 0 rem(12) rem(12))))
      &__overlay
        +media((width: (320: 100%, 663: calc(100% - 64px))))
        +media((height: (320: rem(300), 663: 100%)))
        // +media((overfolow: (320: auto, 663: hidden)))
      &__img
        +media((height: (320: rem(300), 663: 100%)))
        width: 100%
        background-position: center
        background-repeat: no-repeat
        background-color: $default
        transform-origin: 0 0
        background-size: contain
        img
          transform-origin: 0 0
          transform: scale(1)
          max-width: 100%
          max-height: 100%
          display: block
          height: auto
          line-height: 0
        &.grabbing
          cursor: grab
      &__nav
        display: flex
        +media((width: (320: 100%, 663: rem(64))))
        +media((height: (320: rem(64), 663: 100%)))
        justify-content: space-between
        align-items: center
        +media((flex-direction: (320: row-reverse, 663: column)))
        &-zoom
          display: flex
          +media((flex-direction: (320: row-reverse, 663: column)))
          .btn
            &:last-child
              box-shadow: none
        .btn
          background: none
          +media((box-shadow: (320: none, 663: inset 0 rem(-1) 0 #ECECEC)))
          border: none
          outline: none
          cursor: pointer
          height: rem(48)
          width: rem(32)
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          transition: .5s
          &:disabled
            opacity: .5
.modal-enter-active, .modal-leave-active
  transition: .5s
.modal-enter, .modal-leave-to
  -webkit-transform: scale(1.1)
  opacity: 0
  transform: scale(1.1)
</style>
